<template>
  <v-row class="row-faq m-0 pr-md-5" justify="center">
    <v-expansion-panels accordion>
      <v-expansion-panel class="navBarBlue" v-for="(item, i) in faq" :key="i">
        <v-expansion-panel-header class="sideBarBlue">{{ item.title }}</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-3">
          <p class="text-left" v-html="item.description"></p>

          <div class="v-player" v-if="item.video">
            <tutorialVideo />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {
    tutorialVideo: () => import('@/components/Tutorial/Tutorial.vue')
  },
  computed: {
    faq() {
      const currentLang = this.$vuetify.lang.current;
      return this.$vuetify.lang.locales[currentLang].faq;
    }
  }
};
</script>

<style lang="scss">
.faq {
  .v-card {
    background: none;
    box-shadow: none;
  }
}
.row-faq {
  max-width: 1000px;
  .v-player {
    max-height: 600px;
    height: auto;
    max-width: 600px;
    video {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
